import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './slices/api'

import { rootReducer } from './root.reducer'


const setupStore = () => configureStore({
    reducer: rootReducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(apiSlice.middleware)
})

export default setupStore
