import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { FormHelperText } from '@mui/material'
import { apiSlice } from '../../../redux/slices/api'

const SelectComponent = (props) => {
    const { data, isLoading, isError } = apiSlice.useGetEntityQuery(props.endpoint.uri)

    return (
        <Box sx={{ ...props.sx, width: 1 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                <Select onChange={props.onChange} error={props.error} id={props.id} label={props.label} name={props.name} value={props.value} MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}>
                    {isLoading || !data?.providesTags || isError ? (
                        <MenuItem disabled={true}>Загрузка</MenuItem>
                    ) : (
                        data[props.entity].map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item[props.selectname]}
                            </MenuItem>
                        ))
                    )}
                </Select>
                <FormHelperText id="my-helper-text" error={props.error}>
                    {props.helperText}
                </FormHelperText>
            </FormControl>
        </Box>
    )
}

export default SelectComponent
