
export const
    AUTH_LOGIN = 'AUTH_LOGIN',
    AUTH_LOGOUT = 'AUTH_LOGOUT',
    AUTH_CHECK = 'AUTH_CHECK',
    APPLICATION_CREATE = 'APPLICATION_CREATE',
    APPLICATIONS = 'APPLICATIONS',
    PROVIDER_CREATE = 'PROVIDER_CREATE',
    PROVIDERS = 'PROVIDERS',
    DETAIL_CREATE = 'DETAIL_CREATE',
    PRODUCT_CREATE = 'PRODUCT_CREATE',
    DETAILS = 'DETAILS',
    ORDER_CREATE = 'ORDER_CREATE',
    PRODUCTS = 'PRODUCTS',
    ORDERS = 'ORDERS',
    DELETE = 'DELETE',
    PDETAILS = 'PDETAILS',
    LIMIT_TYPES = 'LIMIT_TYPES',
    PRODUCT_LIMITS = 'PRODUCT_LIMITS',
    DETAIL_UPDATE = 'DETAIL_UPDATE',
    ORDER_STATUSES = 'ORDER_STATUSES',
    ORDER_STATUS = 'ORDER_STATUS',
    PROVIDER_UPDATE_ETITYES = 'PROVIDER_UPDATE_ETITYES',
    PROVIDER_UPDATE = 'PROVIDER_UPDATE',
    DETAIL_UPDATE_ETITYES = 'DETAIL_UPDATE_ETITYES',
    DETAIL_UPDATE_ETITYE = 'DETAIL_UPDATE_ETITYE',
    PDETAIL_UPDATE = 'PDETAIL_UPDATE',
    PDETAIL_CREATE = 'PDETAIL_CREATE',
    PRODUCT_UPDATE_ETITYES = 'PRODUCT_UPDATE_ETITYES',
    PRODUCT_UPDATE_ETITYE = 'PRODUCT_UPDATE_ETITYE',
    PRODUCT_UPDATE_PHOTO = 'PRODUCT_UPDATE_PHOTO',
    ORDER_COMMENT = 'ORDER_COMMENT'

const endpoints = {
    [AUTH_LOGIN]: {
        uri: 'auth',
        method: 'POST',
        action: null,
        hasToken: true
    },

    [AUTH_LOGOUT]: {
        uri: 'logout',
        method: 'POST',
        action: null,
        hasToken: true
    },

    [AUTH_CHECK]: {
        uri: 'check',
        method: 'POST',
        action: null,
        hasToken: true
    },
    [APPLICATION_CREATE]: {
        uri: 'application',
        method: 'POST',
        action: null,
    },
    [APPLICATIONS]: {
        uri: 'applications',
        method: 'GET',
        action: null,
    },
    [PROVIDER_CREATE]: {
        uri: 'provider',
        method: 'POST',
        action: null,
    },
    [PROVIDERS]: {
        uri: 'providers',
        method: 'GET',
        action: null,
    },
    [DETAIL_CREATE]: {
        uri: 'detail',
        method: 'post',
        action: null,
    },
    [PRODUCT_CREATE]: {
        uri: 'product',
        method: 'post',
        action: null,
    },
    [PRODUCTS]: {
        uri: 'products',
        method: 'get',
        action: null,
    },
    [DETAILS]: {
        uri: 'details',
        method: 'get',
        action: null,
    },
    [ORDER_CREATE]: {
        uri: 'order',
        method: 'post',
        action: null,
    },
    [ORDERS]: {
        uri: 'order',
        method: 'get',
        action: null,
    },
    [DELETE]: {
        method: 'delete',
        action: null,
    },
    [PDETAILS]: {
        uri: 'pdetails',
        method: 'get',
        action: null,
    },
    [LIMIT_TYPES]: {
        uri: 'limits',
        method: 'get',
        action: null,
    },
    [PRODUCT_LIMITS]: {
        uri: 'product/limits',
        method: 'post',
        action: null,
    },
    [DETAIL_UPDATE]: {
        uri: 'detail/remains',
        method: 'post',
        action: null,
    },
    [ORDER_STATUSES]: {
        uri: 'order/status',
        method: 'get',
        action: null,
    },
    [ORDER_STATUS]: {
        uri: 'order/status',
        method: 'post',
        action: null,
    },
    [PROVIDER_UPDATE_ETITYES]: {
        uri: 'provider/etityes',
        method: 'get',
        action: null,
    },
    [PROVIDER_UPDATE]: {
        uri: 'provider/etityes',
        method: 'post',
        action: null,
    },
    [DETAIL_UPDATE_ETITYES]: {
        uri: 'detail/etityes',
        method: 'get',
        action: null,
    },
    [DETAIL_UPDATE_ETITYE]: {
        uri: 'detail/etityes',
        method: 'post',
        action: null,
    },
    [PDETAIL_UPDATE]: {
        uri: 'pdetail/update',
        method: 'post',
        action: null,
    },
    [PDETAIL_CREATE]: {
        uri: 'pdetail/create',
        method: 'post',
        action: null,
    },
    [PRODUCT_UPDATE_ETITYES]: {
        uri: 'product/etityes',
        method: 'get',
        action: null,
    },
    [PRODUCT_UPDATE_ETITYE]: {
        uri: 'product/etityes',
        method: 'post',
        action: null,
    },
    [PRODUCT_UPDATE_PHOTO]: {
        uri: 'product/photo',
        method: 'post',
        action: null,
        apiForm: true
    },
    [ORDER_COMMENT]: {
        uri: 'order/comment',
        method: 'put',
        action: null,
    }
}

export default endpoints
