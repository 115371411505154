import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    isAuth: false,
    data: null,
    error: null,
    isLoading: false,
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authFetching(state) {
            state.isLoading = true
        },
        authFetchingSuccess(state, action) {
            state.isLoading = false
            state.isAuth = true
            state.data = action.payload
        },
        authSuccess(state, action) {
            state.isLoading = false
            state.isAuth = true
            state.data = action.payload.User
        },
        authLoginError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },
        authLogout(state) {
            state.isAuth = false
            state.data = null
        },
    },
})

export default authSlice.reducer