
import { TextField } from '@mui/material';

const InputPhoto = (props) => {

    return <TextField
        onChange={(e) => props.handlerFile(e)}
        error={props.error}
        id={props.id}
        label={props.label}
        name={props.name}
        type={props.type} 
        sx={props.sx}/>
}


export default InputPhoto