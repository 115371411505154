import { combineReducers } from '@reduxjs/toolkit'

import modalReducer from './slices/modal';
import alertReducer from './slices/alert';
import authReducer from './slices/auth';
import { apiSlice } from './slices/api';


export const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    user: authReducer,
    modal: modalReducer,
    alert: alertReducer,
})
