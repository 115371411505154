import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Button, FormHelperText, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { apiSlice } from '../../../redux/slices/api'

const SelectFileds = (props) => {
    const { data, isLoading, isError } = apiSlice.useGetEntityQuery(props.endpoint.uri)

    const style = {
        box: {
            display: 'flex',
        },
        select: {
            maxWidth: '85%',
        },
        input: {
            maxWidth: '15%',
        },
    }

    return (
        <Box sx={{ ...props.sx, ...style.box }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{props.label || 'Деталь'}</InputLabel>
                <Select defaultValue="" onChange={(e) => props.onselect(e.target.value)} id={props.id} label={props.label || 'Деталь'} name={props.name} value={props.selectvalue} sx={style.select} error={props.error}>
                    {isLoading || !data?.providesTags || isError ? (
                        <MenuItem disabled={true}>Загрузка</MenuItem>
                    ) : (
                        data[props.entity].map((item) => (
                            <MenuItem key={item.id} value={item.id} disabled={props.selectedids.includes(item.id) ? true : false}>
                                {item[props.selectname]}
                            </MenuItem>
                        ))
                    )}
                </Select>
                <FormHelperText id="my-helper-text" error={props.error}>
                    {props.helperText}
                </FormHelperText>
            </FormControl>
            <TextField name={props.name} sx={style.input} onChange={(e) => props.oninput(e.target.value)} value={props.inputvalue} defaultValue={0} label="Кол-во" error={props.error} min="1" />
            <Button onClick={props.ondelete}>
                <ClearIcon />
            </Button>
        </Box>
    )
}

export default SelectFileds
