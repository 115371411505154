import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import AccordionComponent from './Accordion'
import { useDispatch } from 'react-redux'
import { modalSlice } from '../../redux/slices/modal'

const OrderItem = (props) => {
    const dispatch = useDispatch()
    const { openModal } = modalSlice.actions
    const { Application, Status, Comment } = props

    const { id, Name, City, Address, Passport, Phone, FixPrice, Commentaire, createdAt, updatedAt, products, DeliveryType } = Application
    const { onStatus, onDelete, onUpdateNote } = props

    const styles = {
        text: {
            fontSize: 15,
        },
        buttonContainer: {
            mt: 2,
        },
        button: {
            fontSize: 14,
            m: 1.2,
            textTransform: 'none',
        },
    }

    const statuses = (status) => {
        switch (status) {
            case 'processed':
                return { title: 'в работе', color: '#ffeb3b' }
            case 'postponed':
                return { title: 'отложен', color: '#f44336' }
            case 'closed':
                return { title: 'завершён', color: '#4caf50' }
            default:
                return ''
        }
    }

    const onCopy = () => navigator.clipboard.writeText(`${Name}\n${City}\n${Phone}\n${Address}\n${Passport}`)

    const title = (
        <Stack spacing={0.7} onClick={() => onCopy()} sx={{ width: '100%', p: 0.7 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Typography>{Name}</Typography>
                <Box sx={{ width: 15, height: 15, backgroundColor: statuses(Status).color, borderRadius: '50%' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Typography>{DeliveryType !== 'self' ? City : 'самовывоз'}</Typography>
                <Typography sx={{ textAlign: 'left' }}>{Comment}</Typography>
            </Box>
        </Stack>
    )

    const dateFormat = (data) => data.toLocaleDateString('ru-mo', { year: 'numeric', month: 'long', day: 'numeric' }).replace(/[/]/gi, '.')

    const deliveryOptions = {
        pec: 'ПЭК',
        self: 'самовывоз',
        other: 'в другую страну',
    }

    const deleteHandler = (id) => dispatch(openModal({ cfg: 'DELETE', initialData: { id, updatePath: 'ApplicationProducts' } }))
    const quantityHandler = (id) => dispatch(openModal({ cfg: 'APPLICATION_PRODUCT_QUANTITY', initialData: { id, updatePath: 'application/product/quantity' } }))
    const productAdd = (id) => dispatch(openModal({ cfg: 'APPLICATION_PRODUCT', initialData: { id, updatePath: 'application/product' } }))

    return (
        <Stack m={1} spacing={3}>
            <AccordionComponent title={title}>
                <Stack m={1} spacing={1}>
                    <Typography sx={styles.text}>Номер: {Phone}</Typography>
                    <Typography sx={styles.text}>Паспорт: {Passport}</Typography>
                    <Typography sx={styles.text}>Статус: {statuses(Status).title}</Typography>
                    <Typography sx={styles.text}>Комментарий к заказу: {Commentaire}</Typography>
                    <Typography sx={styles.text}>Тип доставки: {deliveryOptions[DeliveryType]}</Typography>
                    {DeliveryType === 'pec' && (
                        <>
                            <Typography sx={styles.text}>Город: {City}</Typography>
                            <Typography sx={styles.text}>
                                {props?.PecDelivery?.isDeleviryToAddress ? 'Адрес' : 'ПВЗ'}: {Address}
                            </Typography>
                        </>
                    )}
                    {DeliveryType === 'other' && (
                        <>
                            <Typography sx={styles.text}>Город: {City}</Typography>
                            <Typography sx={styles.text}>Полный адрес: {Address}</Typography>
                        </>
                    )}
                    <Typography sx={styles.text}>
                        {dateFormat(new Date(createdAt))}
                        {Status === 'closed' ? ' - ' + dateFormat(new Date(updatedAt)) : ''}
                    </Typography>
                    <Typography sx={styles.text}>Товары:</Typography>
                </Stack>
                <Stack ml={3} spacing={1}>
                    <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: 1 }} aria-label="simple table" size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Товар</TableCell>
                                    <TableCell align="left">Кол-во</TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map((row, i) => (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left">{row.Product.Name}</TableCell>
                                        <TableCell align="left" onClick={() => quantityHandler(row.id)}>
                                            {row.Quantity}
                                        </TableCell>
                                        <TableCell align="left" onClick={() => deleteHandler(row.id)}>
                                            <ClearIcon sx={{ fontSize: 13 }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
                {FixPrice&&<Typography sx={{...styles.text, ml: 3, m: 1}}>Промокод: {FixPrice}</Typography>}
                <Stack sx={styles.buttonContainer}>
                    <Button variant="contained" sx={{ ...styles.button }} onClick={onStatus}>
                        Изменить статус
                    </Button>
                    <Button variant="contained" sx={{ ...styles.button }} onClick={onUpdateNote}>
                        Добавить комментарий
                    </Button>
                    <Button variant="contained" sx={{ ...styles.button }} onClick={() => productAdd(id)}>
                        Добавить товар
                    </Button>
                    <Button variant="contained" sx={{ ...styles.button, backgroundColor: '#f44336' }} onClick={onDelete}>
                        Удалить
                    </Button>
                </Stack>
            </AccordionComponent>
        </Stack>
    )
}

export default OrderItem
