import { Route, Routes } from 'react-router'
import Application from './pages/forms/application'
import { protectedRoutes } from '../configs/routes'
import Authentication from './pages/auth'
import { createElement } from 'react'
import Error from './pages/404'
import SuccessApplication from './pages/successApplication'

const Router = ({ isAuth }) => {
    return (
        <Routes>
            {isAuth ? protectedRoutes.map(({ path, element }) => <Route key={path} path={path} element={createElement(element)} />) : null}
            <Route path="/application" element={<Application />} />
            <Route path="/application/success" element={<SuccessApplication />} />
            <Route path="/" element={<Authentication />} />
            <Route path="*" element={<Error />} />
        </Routes>
    )
}

export default Router
