import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, Card, CardHeader, Grid, Stack, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { authLoginAction } from '../../redux/actions/auth'

const Authentication = () => {
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.isAuth) return navigate('/applications')
    }, [user.isAuth, navigate])

    const validationSchema = yup.object({
        Login: yup.string('Enter your email').required('Это обязательное поле'),
        Password: yup.string('Enter your password').required('Это обязательное поле'),
    })

    const formik = useFormik({
        initialValues: {
            Login: '',
            Password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (body) => dispatch(authLoginAction(body)),
    })

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
            <Card sx={{ maxWidth: 450, width: 1 }}>
                <CardHeader title="Авторизация" subheader="Укажите данные учетной записи" />
                <form onSubmit={formik.handleSubmit}>
                    <Stack m={2} spacing={3}>
                        <TextField fullWidth id="Login" name="Login" label="Логин" value={formik.values.Login} onChange={formik.handleChange} error={formik.touched.Login && Boolean(formik.errors.Login)} helperText={formik.touched.Login && formik.errors.Login} />
                        <TextField fullWidth id="Password" name="Password" label="Пароль" type="Password" value={formik.values.Password} onChange={formik.handleChange} error={formik.touched.Password && Boolean(formik.errors.Password)} helperText={formik.touched.Password && formik.errors.Password} />
                        <Button color="primary" variant="contained" fullWidth type="submit" disabled={user.isLoading}>
                            Войти
                        </Button>
                    </Stack>
                </form>
            </Card>
        </Grid>
    )
}


export default Authentication
