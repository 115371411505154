import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'
import endpoints from '../../configs/endpoints'
import ClearIcon from '@mui/icons-material/Clear'
import CircleButton from '../components/CircleButton'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import { useDispatch } from 'react-redux'

const Provider = () => {
    const dispatch = useDispatch()
    const { openModal } = modalSlice.actions

    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.PROVIDERS.uri)

    const tableStyles = {
        row: {
            maxWidth: 'max-content',
        },
    }

    const onUpdate = (id) => dispatch(openModal({ cfg: 'PROVIDER_UPDATE', initialData: { id, updatePath: 'provider' } }))

    return (
        <Box>
            <CircleButton action={() => dispatch(openModal({ cfg: 'PROVIDER_CREATE', initialData: { updatePath: 'provider' } }))} />
            {isLoading || !data?.providers ? (
                <Box>
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: 1 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Название</TableCell>
                                <TableCell align="left">Ссылка</TableCell>
                                <TableCell align="left">Адрес</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Телефон</TableCell>
                                <TableCell align="left">Доб. Номер</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.providers.map((row, i) => (
                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left" sx={tableStyles.row} onClick={() => onUpdate(row.id)}>
                                        {row.Name}
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        {row.Link ? (
                                            <a href={row.Link} target="_blank" rel="noreferrer">
                                                Ссылка
                                            </a>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        {row.Address}
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        {row.Email}
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        <a href={'tel:' + row.Phone}>{row.Phone}</a>
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        {row.SubPhone}
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row} onClick={() => dispatch(openModal({ cfg: 'DELETE', initialData: { id: row.id, updatePath: 'Provider' } }))}>
                                        <ClearIcon />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    )
}

export default Provider
