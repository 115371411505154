
import Detail from "../app/pages/detail";
import Application from "../app/pages/application";
import ProductCreate from "../app/pages/forms/product";
import Product from "../app/pages/products";
import Provider from '../app/pages/provider'
import Orders from '../app/pages/order'

export const protectedRoutes = [
    { path: '/applications', element: Application },
    { path: '/provider', element: Provider },
    { path: '/detail', element: Detail },
    { path: '/products', element: Product },
    { path: '/product/create', element: ProductCreate },
    { path: '/order', element: Orders }
]
