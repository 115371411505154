/* eslint-disable default-param-last */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    cfg: null,
    initialData: {},
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.isOpen = true
            state.cfg = action.payload.cfg
            state.initialData = action.payload.initialData
        },
        closeModal(state) {
            state.isOpen = false
            state.cfg = null
            state.initialData = {}
        },
    },
})

export default modalSlice.reducer
