
import api from '../../app/http'
import { authSlice } from '../slices/auth'

export const authLoginAction = (body, errorAction) => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.authFetching())
            const { data } = await api({ method: 'POST', url: 'auth', data: body })
            dispatch(authSlice.actions.authFetchingSuccess(data))
            localStorage.setItem('Token', data.Token)
        } catch (err) {
            if (errorAction) errorAction(err)
            dispatch(authSlice.actions.authLoginError(err.response.data.msg))
        }
    }
}

export const authCheckAction = () => {
    return async (dispatch) => {
        try {
            dispatch(authSlice.actions.authFetching())
            const { data } = await api({ method: 'POST', url: 'check' })
            dispatch(authSlice.actions.authSuccess(data))
            localStorage.setItem('Token', data.Token)
        } catch (err) {
            dispatch(authSlice.actions.authLoginError(err.response.data.msg))
        }
    }
}

export const authLogoutAction = () => {
    return async (dispatch) => {
        try {
            await api({ method: 'POST', url: 'logout' })
            dispatch(authSlice.actions.authLogout())
            localStorage.removeItem('Token')
        } catch (err) {
            throw err
        }
    }
}
