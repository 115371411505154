import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material'
import { useState } from 'react'

const SelectAutocomplete = ({ id, label, options, onChange, onInput, error, helperText, isLoading }) => {
    const [inputValue, setInputValue] = useState('')
    const [value, setValue] = useState('')
    return (
        <Box>
            <FormControl fullWidth>
                <Autocomplete
                    disablePortal
                    id={id}
                    name={id}
                    options={options}
                    inputValue={inputValue.length ? inputValue : value}
                    value={value}
                    onChange={(e, newValue) => {
                        setValue(newValue || '')
                        onChange(newValue ? newValue.branchId || newValue.cityTitle : '')
                    }}
                    onInputChange={(e, query) => {
                        setInputValue(query)
                        onInput(query)
                    }}
                    getOptionLabel={(option) => (option ? option.cityTitle || option.branchTitle : '')}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    loading={isLoading}
                    loadingText={'Загрузка...'}
                    noOptionsText={'Заполните поле'}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                <FormHelperText error={error}>{helperText}</FormHelperText>
            </FormControl>
        </Box>
    )
}

export default SelectAutocomplete
