import { Card, Typography } from "@mui/material"
import { Box } from "@mui/system"
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { createElement } from "react";
import { Link, useLocation } from "react-router-dom";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';


const Navigation = () => {

    const buttons = [{
        link: '/applications',
        title: 'Заявки',
        icon: NewspaperIcon
    }, {
        link: '/order',
        title: 'Заказы',
        icon: BookmarkBorderOutlinedIcon
    }, {
        link: '/products',
        title: 'Товары',
        icon: ProductionQuantityLimitsIcon
    }, {
        link: '/detail',
        title: 'Детали',
        icon: SettingsOutlinedIcon
    }, {
        link: '/provider',
        title: 'Поставщики',
        icon: AccountBoxOutlinedIcon
    }]

    const { pathname } = useLocation()

    const style = {
        'container': {
            container: true,
            position: 'fixed',
            bottom: 0,
            width: 1,
            zIndex: 1100,
            display: 'flex',
            justifyContent: 'center',
        },
        'box': {
            display: 'flex',
            justifyContent: 'space-between',
            maxWidth: '650px',
            width: 1,
        },
        'item': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#000',
            p: 1,
            m: 0.4,
            mt: 1,
            mb: 1,
            borderRadius: '10%',
            fontSize: 13,
        },
        'icon': {

        }
    }

    return (
        <Card sx={style.container}>
            <Box sx={style.box}>
                {buttons.map((btn, i) => <Link to={btn.link} key={i}>
                    <Box sx={{ ...style.item, backgroundColor: pathname === btn.link ? 'primary.main' : '#fff', color: pathname === btn.link ? '#fff' : '#000' }}>
                        {createElement(btn.icon, { sx: style.icon })}
                        <Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>{btn.title}</Typography>
                    </Box>
                </Link>)}
            </Box>
        </Card>
    )
}
export default Navigation