import { Button, Card, CardHeader, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import InputMask from 'react-input-mask'
import { apiSlice } from '../../../redux/slices/api'
import { alertSlice } from '../../../redux/slices/alert'
import { useEffect, useState } from 'react'
import SelectFileds from '../../components/UI/SelectFileds'
import endpoints from '../../../configs/endpoints'
import SelectAutocomplete from '../../components/UI/SelectAutocomplete'
import CheckBoxComponent from '../../components/UI/Checkbox'
import { useNavigate } from 'react-router-dom'

const Application = () => {
    const { openAlert } = alertSlice.actions
    const [
        createApplication,
        {
            isLoading: { isLoading },
        },
    ] = apiSlice.useApplicationCreateMutation()
    const [searchFn, { isLoading: isLoadingSearch }] = apiSlice.useCitySearchMutation()
    const [divisionsFn, { isLoading: isLoadingDivisions }] = apiSlice.useDivisionsSearchMutation()
    const navigation = useNavigate()
    const [cities, setCities] = useState([])
    const [divisions, setDivisions] = useState([])
    const [detailFields, setDetailFields] = useState([{ Quantity: 1 }])

    const validationSchema = yup.object().shape({
        Name: yup.string('Enter your email').required('Это обязательное поле'),
        Phone: yup.number().typeError('Укажите номер телефона цифрами').required('Это обязательное поле'),
        DeliveryType: yup.string('Enter your Email').required('Это обязательное поле'),
        City: yup.string('Enter your Email').when('DeliveryType', {
            is: (DeliveryType) => (DeliveryType === 'pec' || DeliveryType === 'other' ? true : false),
            then: yup.string('Enter your Email').required('Это обязательное поле'),
            otherwise: yup.string(),
        }),
        Address: yup.string('Укажите адрес').when('DeliveryType', {
            is: (DeliveryType) => (DeliveryType === 'pec' || DeliveryType === 'other' ? true : false),
            then: yup.string('Enter your Email').required('Это обязательное поле'),
            otherwise: yup.string(),
        }),
        Passport: yup.string('Укажите адрес').when('DeliveryType', {
            is: (DeliveryType) => (DeliveryType === 'pec' || DeliveryType === 'other' ? true : false),
            then: yup.string('Enter your Email').required('Это обязательное поле'),
            otherwise: yup.string(),
        }),
        FixPrice: yup.string('Укажите промокод строкой'),
        Commentaire: yup.string('Укажите комментарий строкой'),
        isDeleviryToAddress: yup.boolean().required('Это обязательное поле'),
    })

    const formik = useFormik({
        initialValues: {
            Name: '',
            Phone: '',
            isDeleviryToAddress: false,
            DeliveryType: 'pec',
            City: '',
            Address: '',
            Passport: '',
            FixPrice: '',
            Commentaire: '',
        },
        validationSchema: validationSchema,
        onSubmit: (data, { setFieldError, resetForm }) => {
            const pecCity = cities.find(({ branchId }) => branchId === data.City)
            const pecDevision = divisions.find(({ id }) => id === data.Address)
            const body = {
                ...data,
                City: data.DeliveryType === 'pec' ? pecCity.cityTitle || pecCity.branchTitle : data.City,
                Address: data.DeliveryType === 'pec' && !data.isDeleviryToAddress ? pecDevision.name : data.Address,
                products: detailFields,
                pec: {
                    city: data.City,
                    division: data.isDeleviryToAddress ? null : data.Address,
                    address: data.isDeleviryToAddress ? data.Address : null,
                },
            }
            createApplication({ body })
                .unwrap()
                .then((data) => {
                    openAlert(data.message)
                    resetForm()
                    setDetailFields([{ Quantity: 1 }])
                    setCities([])
                    setDivisions([])
                    navigation(`/application/success?order=${data.id}`)
                })
                .catch((err) => err?.data.fields?.map(({ name, message }) => setFieldError(name, message)))
        },
    })

    const handlerChange = (type, value, i) => {
        let values = detailFields
        values[i][type] = value
        setDetailFields([...values])
    }

    const handlerDelete = (i) => {
        let values = detailFields
        if (values.length === 1) return
        values.splice(i, 1)
        setDetailFields([...values])
    }

    let delayTimer
    const citySearch = (query) => {
        clearTimeout(delayTimer)
        delayTimer = setTimeout(() => {
            searchFn(query)
                .unwrap()
                .then((res) => {
                    setCities([...res, ...cities])
                })
        }, 1000)
    }

    useEffect(() => {
        if (formik.values.City.length)
            divisionsFn(formik.values.City)
                .unwrap()
                .then((res) => setDivisions(res))
    }, [formik.values.City])

    const deliveryOptions = [
        { label: 'Самовывоз', id: 'self' },
        { label: 'Доставка', id: 'pec' },
        { label: 'Другие страны', id: 'other' },
    ]

    const resetField = (field) => formik.setFieldValue(field, '')

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Card sx={{ maxWidth: 650, width: 1 }}>
                <CardHeader title="Оформление заказа" subheader="Укажите данные для создания заказа" />
                <form onSubmit={formik.handleSubmit}>
                    <Stack m={2} spacing={3}>
                        <TextField fullWidth id="Name" name="Name" label="ФИО" value={formik.values.Name} onChange={formik.handleChange} error={formik.touched.Name && Boolean(formik.errors.Name)} helperText={formik.touched.Name && formik.errors.Name} />
                        <TextField fullWidth id="Phone" name="Phone" label="Номер телефона" value={formik.values.Phone} onChange={formik.handleChange} error={formik.touched.Phone && Boolean(formik.errors.Phone)} helperText={formik.touched.Phone && formik.errors.Phone} />
                        <FormControl fullWidth>
                            <InputLabel>Тип доставки</InputLabel>
                            <Select id="DeliveryType" name="DeliveryType" label="Тип доставки" value={formik.values.DeliveryType} onChange={formik.handleChange}>
                                {deliveryOptions.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        onClick={() => {
                                            resetField('Address')
                                            resetField('City')
                                        }}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {formik.values.DeliveryType === 'pec' && (
                            <>
                                <SelectAutocomplete isLoading={isLoadingSearch} onInput={(query) => citySearch(query)} options={cities} id="City" name="City" label="Город" value={formik.values.City} onChange={(data) => formik.setFieldValue('City', data)} error={formik.touched.City && Boolean(formik.errors.City)} helperText={formik.touched.City && formik.errors.City} isOptionEqualToValue={(option, value) => option.id === value.id} />
                                <CheckBoxComponent
                                    id="isDeleviryToAddress"
                                    label="Доставка до адреса"
                                    value={formik.values.isDeleviryToAddress}
                                    change={(field, value) => {
                                        resetField('Address')
                                        formik.setFieldValue(field, value)
                                    }}
                                />
                                {formik.values.isDeleviryToAddress ? (
                                    <Tooltip title={'Адрес доставки в формате Россия, Оренбургская область, Бугуруслан, улица Строителей, 15'} placement="bottom-start">
                                        <TextField fullWidth id="Address" name="Address" label="Адрес доставки в формате Россия, Оренбургская область, Бугуруслан, улица Строителей, 15" value={formik.values.Address} onChange={formik.handleChange} error={formik.touched.Address && Boolean(formik.errors.Address)} helperText={formik.touched.Address && formik.errors.Address} />
                                    </Tooltip>
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel>Адрес филиалла ПЭК</InputLabel>
                                        <Select defaultValue="" id="Address" name="Address" label="Адрес филиалла ПЭК" value={formik.values.Address} onChange={formik.handleChange} error={formik.touched.Address && Boolean(formik.errors.Address)} helperText={formik.touched.Address && formik.errors.Address}>
                                            {isLoadingDivisions || !divisions.length ? (
                                                <MenuItem disabled={true}>Укажите город</MenuItem>
                                            ) : (
                                                divisions.map((item) => (
                                                    <MenuItem key={item.id} value={item.id} sx={{ whiteSpace: 'initial' }}>
                                                        <Typography>{item.addressDivision}</Typography>
                                                    </MenuItem>
                                                ))
                                            )}
                                        </Select>
                                        <FormHelperText error={formik.touched.Instagram && Boolean(formik.errors.Instagram)}>{formik.touched.City && formik.errors.City}</FormHelperText>
                                    </FormControl>
                                )}
                                <InputMask mask="9999 999999" value={formik.values.Passport} disabled={false} onChange={formik.handleChange} maskChar=" ">
                                    <TextField fullWidth id="Passport" name="Passport" label="Серия и номер паспорта для службы доставки" value={formik.values.Passport} error={formik.touched.Passport && Boolean(formik.errors.Passport)} helperText={formik.touched.Passport && formik.errors.Passport} />
                                </InputMask>
                            </>
                        )}
                        {formik.values.DeliveryType === 'other' && (
                            <>
                                <TextField fullWidth id="City" name="City" label="Город" value={formik.values.City} onChange={formik.handleChange} error={formik.touched.City && Boolean(formik.errors.City)} helperText={formik.touched.City && formik.errors.City} />
                                <TextField fullWidth id="Address" name="Address" label="Полный адрес" value={formik.values.Address} onChange={formik.handleChange} error={formik.touched.Address && Boolean(formik.errors.Address)} helperText={formik.touched.Address && formik.errors.Address} />
                                <InputMask mask="" value={formik.values.Passport} disabled={false} onChange={formik.handleChange} maskChar=" ">
                                    <TextField fullWidth id="Passport" name="Passport" label="Серия и номер паспорта для службы доставки" value={formik.values.Passport} error={formik.touched.Passport && Boolean(formik.errors.Passport)} helperText={formik.touched.Passport && formik.errors.Passport} />
                                </InputMask>
                            </>
                        )}
                        <TextField fullWidth id="FixPrice" name="FixPrice" label="Промокод" value={formik.values.FixPrice} onChange={formik.handleChange} error={formik.touched.FixPrice && Boolean(formik.errors.FixPrice)} helperText={formik.touched.FixPrice && formik.errors.FixPrice} />
                        <TextField fullWidth multiline id="Commentaire" name="Commentaire" label="Комментарии к заказу" minRows={3} maxRows={6} value={formik.values.Commentaire} onChange={formik.handleChange} error={formik.touched.Commentaire && Boolean(formik.errors.Commentaire)} helperText={formik.touched.Commentaire && formik.errors.Commentaire} />
                        {detailFields.map((detail, i) => (
                            <SelectFileds label={'Оборудование'} entity="products" key={detail.key} endpoint={endpoints.PRODUCTS} selectValue={detail.id} onselect={(id) => handlerChange('ProductId', id, i)} oninput={(Quantity) => handlerChange('Quantity', Quantity, i)} selectvalue={detailFields[i].ProductId} inputvalue={detailFields[i].Quantity} selectname="Name" id={detail.id} name={detail.id} ondelete={() => handlerDelete(i)} error={detailFields[i].error} selectedids={detailFields.map(({ ProductId }) => ProductId)} />
                        ))}
                        <Button color="primary" variant="contained" sx={{ width: 1 / 2 }} onClick={() => setDetailFields([...detailFields, { key: Math.random(), ProductId: '', Quantity: 0, error: false }])}>
                            Добавить оборудование
                        </Button>
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            {isLoading ? <CircularProgress /> : 'Оставить заявку'}
                        </Button>
                    </Stack>
                </form>
            </Card>
        </Grid>
    )
}

export default Application
