import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import Compose from './app';
import setupStore from './redux';




const store = setupStore()

const App =
  <React.StrictMode>
    <Provider store={store}>
      <Compose />
    </Provider>
  </React.StrictMode>

render(App, document.getElementById('root'));
