import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AccordionComponent from './Accordion'

import endpoints from '../../configs/endpoints'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import { useDispatch } from 'react-redux'
import { alertSlice } from '../../redux/slices/alert'

const ProductItem = (props) => {
    const dispatch = useDispatch()
    const { id, Name, Description, Limit, RPrice, Photo, Remains, Weight, Statistics } = props
    const { onDelete, onLimits, onProductCopy } = props

    const { openModal } = modalSlice.actions
    const { openAlert } = alertSlice.actions

    const culcPrice = (items) => (items.map((item) => item.Quantity * item.Detail.Price, 0).length ? items.map((item) => item.Quantity * item.Detail.Price, 0).reduce((p, c) => p + c, 0) : 0)

    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.PDETAILS.uri + '/' + id)

    const styles = {
        text: {
            fontSize: 15,
        },
        buttonContainer: {
            display: 'flex',
            mt: 2,
        },
        button: {
            fontSize: 14,
            m: 1.2,
            ml: 0,
            textTransform: 'none',
        },
        imgWrapper: {},
    }

    const [updateRemains, { isLoading: remainsLoading }] = apiSlice.useUpdateEntityMutation()

    const updateHandler = (id) => dispatch(openModal({ cfg: 'PRODUCT_UPDATE_ETITYE', initialData: { id, updatePath: 'product/etityes' } }))
    const updatePhotoHandler = (id) => dispatch(openModal({ cfg: 'PRODUCT_UPDATE_PHOTO', initialData: { id } }))
    const pdetailCreateHandler = (ProductId) => dispatch(openModal({ cfg: 'PDETAIL_CREATE', initialData: { ProductId, updatePath: 'pdetail' } }))
    const pdetailUpdateHandler = (id) => dispatch(openModal({ cfg: 'PDETAIL_UPDATE', initialData: { id, updatePath: 'pdetail' } }))

    const remainsHandler = (Remains) =>
        updateRemains({ body: { id, Remains }, updatePath: 'product/remains' })
            .unwrap()
            .catch((err) => err.data?.message && dispatch(openAlert(err.data?.message)))

    return (
        <Stack m={1} spacing={0} sx={{ boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
            <AccordionComponent title={Name} sx={{ boxShadow: 'none' }}>
                {!isLoading || data?.pdetails ? (
                    <>
                        {Photo.length > 0 ? (
                            <Box sx={styles.imgWrapper} onClick={() => updatePhotoHandler(id)}>
                                <img alt="Изображение" src={'/api/img/' + Photo} style={{ maxWidth: '100%' }} />
                            </Box>
                        ) : (
                            <Typography sx={{ color: 'primary.main', cursor: 'pointer' }} onClick={() => updatePhotoHandler(id)}>
                                Добавить Изображение
                            </Typography>
                        )}
                        <Stack m={1} spacing={1}>
                            <Typography sx={styles.text} onClick={() => updateHandler(id)}>
                                Описание: {Description}
                            </Typography>
                            <Typography sx={styles.text} onClick={() => updateHandler(id)}>
                                Вес: {Weight} кг.
                            </Typography>
                            <Typography sx={styles.text} onClick={() => updateHandler(id)}>
                                Розничная стоимость: {RPrice} Руб.
                            </Typography>
                            <Typography sx={styles.text} onClick={() => updateHandler(id)}>
                                Себестоимость: {culcPrice(data.pdetails)} Руб.
                            </Typography>
                            <Typography sx={styles.text} onClick={() => updateHandler(id)}>
                                Реализовано: {Statistics.totalSales} шт.
                            </Typography>
                            <Typography sx={styles.text}>Лимиты:</Typography>
                        </Stack>
                        <Stack ml={3} spacing={1}>
                            <Typography sx={styles.text} onClick={onLimits}>
                                Минимальный: {Limit.Low} ед.
                            </Typography>
                            <Typography sx={styles.text} onClick={onLimits}>
                                Средний: {Limit.Mid} ед.
                            </Typography>
                        </Stack>
                        <AccordionComponent
                            title={
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
                                    <Typography sx={{ fontSize: 15 }}>Детали</Typography>
                                    <AddIcon sx={{ mr: 2 }} onClick={() => pdetailCreateHandler(id)} />
                                </Box>
                            }
                            sx={{ mt: 4 }}
                        >
                            <TableContainer>
                                <Table sx={{ maxWidth: 1 }} aria-label="simple table" size="medium">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Название</TableCell>
                                            <TableCell align="left">Кол-во</TableCell>
                                            <TableCell align="left">Цена</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...data.pdetails]
                                            .sort((a, b) => (a.Detail.Name > b.Detail.Name ? 1 : -1))
                                            .map((row, i) => (
                                                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="left">{row.Detail.Name}</TableCell>
                                                    <TableCell align="left" onClick={() => pdetailUpdateHandler(row.id)}>
                                                        {row.Quantity}
                                                    </TableCell>
                                                    <TableCell align="left">{row.Quantity * row.Detail.Price}</TableCell>
                                                    <TableCell>
                                                        <CloseIcon sx={{ fontSize: 17 }} onClick={() => dispatch(openModal({ cfg: 'DELETE', initialData: { id: row.id, updatePath: 'PDetail' } }))} />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionComponent>
                    </>
                ) : null}
                <Box sx={styles.buttonContainer}>
                    <Button variant="contained" sx={{ ...styles.button }} onClick={onProductCopy}>
                        Копировать
                    </Button>
                    <Button variant="contained" sx={{ ...styles.button, backgroundColor: '#f44336' }} onClick={onDelete}>
                        Удалить
                    </Button>
                </Box>
            </AccordionComponent>
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Button disabled={remainsLoading} onClick={() => remainsHandler(1)}>
                    +
                </Button>
                <Typography>{Remains}</Typography>
                <Button disabled={remainsLoading} onClick={() => (Remains - 1 >= 0 ? remainsHandler(-1) : null)}>
                    -
                </Button>
            </Stack>
        </Stack>
    )
}

export default ProductItem
