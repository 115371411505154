import { Button, Card, CardHeader, Grid, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import { useState } from 'react'
import endpoints from '../../../configs/endpoints'
import SelectFileds from '../../components/UI/SelectFileds'
import * as yup from 'yup'
import { apiSlice } from '../../../redux/slices/api'
import { alertSlice } from '../../../redux/slices/alert'
import { useDispatch } from 'react-redux'

const ProductCreate = () => {
    const { openAlert } = alertSlice.actions
    const dispatch = useDispatch()
    const [photo, setPhoto] = useState('')
    const [
        createProduct,
        {
            isLoading: { isLoading },
        },
    ] = apiSlice.useProductCreateMutation()

    const formik = useFormik({
        initialValues: {
            Name: '',
            Description: '',
            Llow: '',
            Lmid: '',
            RPrice: 0,
            Photo: '',
        },
        validationSchema: yup.object({
            Name: yup.string('Укажите имя поставщика').required('Это обязательное поле'),
            Description: yup.string('Укажите имя поставщика').required('Это обязательное поле'),
            Llow: yup.number().typeError('Укажите лимит цифрой').required('Это обязательное поле'),
            Lmid: yup.number().typeError('Укажите лимит цифрой').required('Это обязательное поле'),
            RPrice: yup.number().typeError('Укажите цену цифрой').required('Это обязательное поле'),
        }),
        onSubmit: async (values, { setFieldError, resetForm }) => {
            // if (detailFields.filter(({ DetailId, Quantity }) => DetailId.length === 0 || isNaN(Quantity)).length) return handlerError()
            const form_data = new FormData()
            for (let key in values) form_data.append(key, values[key])
            form_data.append('Details', JSON.stringify(detailFields))
            form_data.append('file', photo)

            await createProduct(form_data)
                .unwrap()
                .then((data) => {
                    dispatch(openAlert(data.message))
                    resetForm()
                    setDetailFields([{ key: Math.random(), DetailId: '', Quantity: 0, error: false }])
                })
                .catch((err) => err.data.fields?.map(({ name, msg }) => setFieldError(name, msg)))
        },
    })

    const [detailFields, setDetailFields] = useState([])

    const handlerChange = (type, value, i) => {
        let values = detailFields
        values[i][type] = value
        setDetailFields([...values])
    }

    const handlerDelete = (i) => {
        let values = detailFields
        if (values.length === 1) return
        values.splice(i, 1)
        setDetailFields([...values])
    }

    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
            <Card sx={{ maxWidth: 650, width: 1 }}>
                <CardHeader title="Добавление товара" subheader="Укажите данные для создания товара" />
                <Box
                    component="form"
                    onSubmit={formik.handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 700,
                        minWidth: '320px',
                    }}
                >
                    <Stack m={2} spacing={3}>
                        <TextField fullWidth id="Name" name="Name" label="Название" value={formik.values.Name} onChange={formik.handleChange} error={formik.touched.Name && Boolean(formik.errors.Name)} helperText={formik.touched.Name && formik.errors.Name} />
                        <TextField fullWidth id="Description" name="Description" label="Описание" value={formik.values.Description} onChange={formik.handleChange} error={formik.touched.Description && Boolean(formik.errors.Description)} helperText={formik.touched.Description && formik.errors.Description} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <TextField fullWidth id="Llow" name="Llow" label="Минимальное кол-во" value={formik.values.Llow} onChange={formik.handleChange} error={formik.touched.Llow && Boolean(formik.errors.Llow)} helperText={formik.touched.Llow && formik.errors.Llow} sx={{ maxWidth: '49%' }} />
                            <TextField fullWidth id="Lmid" name="Lmid" label="Среднее кол-во" value={formik.values.Lmid} onChange={formik.handleChange} error={formik.touched.Lmid && Boolean(formik.errors.Lmid)} helperText={formik.touched.Lmid && formik.errors.Lmid} sx={{ maxWidth: '49%' }} />
                        </Box>
                        <TextField fullWidth id="RPrice" name="RPrice" label="Розничная стоимость" value={formik.values.RPrice} onChange={formik.handleChange} error={formik.touched.RPrice && Boolean(formik.errors.RPrice)} helperText={formik.touched.RPrice && formik.errors.RPrice} />
                        <TextField fullWidth id="Photo" name="Photo" type="file" accept="image/*" label="" onChange={(e) => setPhoto(e.currentTarget.files[0])} />
                        {detailFields.length ? detailFields.map((detail, i) => <SelectFileds entity="details" key={detail.key} endpoint={endpoints.DETAILS} selectValue={detail.id} onselect={(id) => handlerChange('DetailId', id, i)} oninput={(Quantity) => handlerChange('Quantity', Quantity, i)} selectvalue={detailFields[i].DetailId} inputvalue={detailFields[i].Quantity} selectname="Name" id={detail.id} name={detail.id} ondelete={() => handlerDelete(i)} error={detailFields[i].error} selectedids={detailFields.map(({ DetailId }) => DetailId)} />) : null}
                        <Button color="primary" variant="contained" sx={{ width: 1 / 2 }} onClick={() => setDetailFields([...detailFields, { key: Math.random(), DetailId: '', Quantity: 0, error: false }])}>
                            Добавить деталь
                        </Button>
                        <Button color="primary" variant="contained" fullWidth type="submit" disabled={isLoading}>
                            Создать
                        </Button>
                    </Stack>
                </Box>
            </Card>
        </Grid>
    )
}

export default ProductCreate
