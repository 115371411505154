const { FormGroup, FormControlLabel, Checkbox } = require('@mui/material')

const CheckBoxComponent = ({ id, label, disabled, value, change, component }) => (


    <FormGroup>
        <FormControlLabel
            disabled={disabled}
            sx={component?.sx}
            control={<Checkbox checked={Boolean(value)} />}
            onChange={({ target: { checked } }) => change(id, checked)}
            label={label}
        />
    </FormGroup>
)

export default CheckBoxComponent
