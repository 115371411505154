import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AccordionComponent = ({ title, children, sx }) => {
    return (
        <Accordion sx={sx}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                variant="contained"
            >
                {typeof title === 'object' ? title : <Typography sx={{ fontSize: 18 }}>{title}</Typography>}
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionComponent