/* eslint-disable import/prefer-default-export */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    content: null,
}

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        openAlert(state, action) {
            state.isOpen = true
            state.content = action.payload
        },
        closeAlert(state, action) {
            state.isOpen = false
            state.content = null
        },
    },
})

export default alertSlice.reducer
