import { Box, Button, Card, Modal, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { alertSlice } from '../../redux/slices/alert'

const AlertComponent = () => {
    const dispatch = useDispatch()
    const { isOpen, content } = useSelector((state) => state.alert)

    const { closeAlert } = alertSlice.actions

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 1 / 2.5,
        minWidth: '320px',
        p: 2,
        pt: 2.5,
    }

    const styleContainer = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        container: true,
    }

    const closeHandler = () => dispatch(closeAlert())

    return (
        <Modal open={isOpen} onClose={() => closeHandler()} aria-labelledby="alert" aria-describedby="modal-modal-description">
            <Card sx={style}>
                <Box sx={styleContainer}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2.5 }}>
                        {content}
                    </Typography>
                    <Button onClick={() => closeHandler()}>Ок</Button>
                </Box>
            </Card>
        </Modal>
    )
}

export default AlertComponent
