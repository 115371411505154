import axios from "axios";
import endpoints from "../../configs/endpoints";


const apiForm = axios.create({
  baseURL: process.env.NODE_ENV !== "production" ? 'http://localhost:8080/api/' : '/api/',
  withCredentials: true,
  headers: { "content-type": "multipart/form-data" },
});

apiForm.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem("Token")}`;
  return request;
});

apiForm.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.post(`${process.env.NODE_ENV !== "production" ? 'http://localhost:8080/api/' : '/api/'}${endpoints.AUTH_CHECK.uri}`);
        localStorage.setItem("Token", response.data.Token);
        return apiForm.request(originalRequest);
      } catch (e) {
        throw e;
      }
    }
    throw error;
  }
);
export default apiForm;
