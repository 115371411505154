const Error = () => {

    return (<>
        <div>404</div>
        <div class="txt">
            Not Found<span class="blink">_</span>
        </div>
    </>)
}

export default Error