import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

const CircleButton = ({ action }) => {

    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        bottom: 120,
        right: 20,
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: 'primary.main',
        cursor: 'pointer',
        zIndex: 1101
    }

    return (
        <Box onClick={action} sx={style}>
            <AddIcon sx={{ color: '#fff' }} />
        </Box>
    )
}

export default CircleButton