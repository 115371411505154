import { TextField, Typography } from "@mui/material"
import endpoints from "./endpoints"
import * as yup from 'yup';
import SelectComponent from "../app/components/UI/SelectComponent";
import InputPhoto from "../app/components/UI/InputPhoto";
import { apiSlice } from "../redux/slices/api";
import CheckBoxComponent from "../app/components/UI/Checkbox";

export const
    PROVIDER_CREATE = 'PROVIDER_CREATE',
    PROVIDER_UPDATE = 'PROVIDER_UPDATE',
    DETAIL_CREATE = 'DETAIL_CREATE',
    ORDER_CREATE = 'ORDER_CREATE',
    DELETE = 'DELETE',
    PRODUCT_LIMITS = 'PRODUCT_LIMITS',
    DETAIL_UPDATE = 'DETAIL_UPDATE',
    ORDER_STATUS = 'ORDER_STATUS',
    DETAIL_UPDATE_ETITYE = 'DETAIL_UPDATE_ETITYE',
    PDETAIL_UPDATE = 'PDETAIL_UPDATE',
    PDETAIL_CREATE = 'PDETAIL_CREATE',
    PRODUCT_UPDATE_ETITYE = 'PRODUCT_UPDATE_ETITYE',
    PRODUCT_UPDATE_PHOTO = 'PRODUCT_UPDATE_PHOTO',
    PRODUCT_COPY = 'PRODUCT_COPY',
    ORDER_COMMENT = 'ORDER_COMMENT',
    APPLICATION_PRODUCT_QUANTITY = 'APPLICATION_PRODUCT_QUANTITY',
    APPLICATION_PRODUCT = 'APPLICATION_PRODUCT'

const modals = {
    [PROVIDER_CREATE]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Добавить поставщика',
        form: {
            fields: [{
                component: TextField,
                props: {
                    id: 'Name',
                    name: 'Name',
                    label: 'Название'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Link',
                    name: 'Link',
                    label: 'Ссылка'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Address',
                    name: 'Address',
                    label: 'Адрес'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Email',
                    name: 'Email',
                    label: 'Email'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Phone',
                    name: 'Phone',
                    label: 'Номер телефона'
                }
            }, {
                component: TextField,
                props: {
                    id: 'SubPhone',
                    name: 'SubPhone',
                    label: 'Добавочный номер'
                }
            }],
            submitText: 'Создать',
            validation: {
                Name: yup
                    .string('Укажите имя поставщика')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Name: '',
                Link: '',
                Address: '',
                Email: '',
                Phone: '',
                SubPhone: ''
            }
        },
        endpoint: endpoints.PROVIDER_CREATE
    },
    [PROVIDER_UPDATE]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение поставщика',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'Etity',
                    name: 'Etity',
                    label: 'Поле',
                    endpoint: endpoints.PROVIDER_UPDATE_ETITYES,
                    selectname: 'Name',
                    entity: 'providerValues'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Value',
                    name: 'Value',
                    label: 'Значение'
                }
            }],
            submitText: 'Изменить',
            validation: {
                Etity: yup
                    .string('Значение из списка')
                    .required('Это обязательное поле'),
                Value: yup
                    .string('Значение из списка')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Etity: '',
                Value: ''
            }
        },
        endpoint: endpoints.PROVIDER_UPDATE
    },
    [DETAIL_CREATE]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Добавить деталь',
        form: {
            fields: [{
                component: TextField,
                props: {
                    id: 'Name',
                    name: 'Name',
                    label: 'Название'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Price',
                    name: 'Price',
                    label: 'Цена'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Remains',
                    name: 'Remains',
                    label: 'Остаток'
                }
            }, {
                component: SelectComponent,
                props: {
                    id: 'ProviderId',
                    name: 'ProviderId',
                    label: 'Поставщик',
                    endpoint: endpoints.PROVIDERS,
                    selectname: 'Name',
                    entity: 'providers'
                }
            }],
            submitText: 'Создать',
            validation: {
                Name: yup
                    .string('Укажите имя поставщика')
                    .required('Это обязательное поле'),
                Price: yup
                    .number()
                    .typeError('Укажите цену цифрой')
                    .required('Это обязательное поле'),
                Remains: yup
                    .number()
                    .typeError('Укажите остаток цифрой')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Name: '',
                Link: '',
                Price: '',
                Remains: '',
                ProviderId: '',
            }
        },
        endpoint: endpoints.DETAIL_CREATE
    },
    [DETAIL_UPDATE_ETITYE]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение детали',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'Etity',
                    name: 'Etity',
                    label: 'Поле',
                    endpoint: endpoints.DETAIL_UPDATE_ETITYES,
                    selectname: 'Name',
                    entity: 'detailValues'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Value',
                    name: 'Value',
                    label: 'Значение'
                }
            }, {
                component: SelectComponent,
                props: {
                    id: 'ProviderId',
                    name: 'ProviderId',
                    label: 'Поставщик',
                    endpoint: endpoints.PROVIDERS,
                    selectname: 'Name',
                    entity: 'providers'
                }
            }],
            submitText: 'Изменить',
            validation: {},
            initialValues: {
                Etity: '',
                Value: '',
                ProviderId: ''
            }
        },
        endpoint: endpoints.DETAIL_UPDATE_ETITYE
    },
    [DELETE]: {
        hook: apiSlice.useDeleteEntityMutation,
        title: 'Подтвердите удаление',
        form: {
            fields: [{
                component: Typography,
                props: {
                    children: 'Вы действительно хотите удалить это ?',
                    sx: { mb: 3, color: '#000!important' }
                }
            }],
            submitText: 'Удалить',
            submitColor: '#f44336',
            closeButton: 'Отменить',
            validation: {},
            initialValues: {}
        },
        endpoint: endpoints.DELETE
    },
    [PRODUCT_LIMITS]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение лимитов товара',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'LimitType',
                    name: 'LimitType',
                    label: 'Лимит',
                    endpoint: endpoints.LIMIT_TYPES,
                    selectname: 'Name',
                    entity: 'limitstypes'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Value',
                    name: 'Value',
                    label: 'Значение лимита'
                }
            }],
            submitText: 'Обновить лимит',
            validation: {
                LimitType: yup
                    .string('Лимит из списка')
                    .required('Это обязательное поле'),
                Value: yup
                    .number()
                    .typeError('Укажите лимит цифрой')
                    .required('Это обязательное поле')
            },
            initialValues: {
                LimitType: '',
                Value: '',
            }
        },
        endpoint: endpoints.PRODUCT_LIMITS
    },
    [DETAIL_UPDATE]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение остатка',
        form: {
            fields: [{
                component: TextField,
                props: {
                    id: 'Remains',
                    name: 'Remains',
                    label: 'Добавить'
                }
            }],
            submitText: 'Обновить',
            validation: {
                Remains: yup
                    .number()
                    .typeError('Укажите остаток цифрой')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Remains: '',
            }
        },
        endpoint: endpoints.DETAIL_UPDATE
    },
    [ORDER_STATUS]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение статуса',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'Status',
                    name: 'Status',
                    label: 'Статус',
                    endpoint: endpoints.ORDER_STATUSES,
                    selectname: 'Name',
                    entity: 'orderstatuses'
                }
            }, {
                component: CheckBoxComponent,
                props: {
                    id: 'isDelivery',
                    name: 'isDelivery',
                    label: 'Предварительное оформление ПЭК',
                }
            }],
            submitText: 'Обновить',
            validation: {
                Status: yup
                    .string('Лимит из списка')
                    .required('Это обязательное поле'),
                isDelivery: yup
                    .boolean()
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Status: '',
                isDelivery: true
            }
        },
        endpoint: endpoints.ORDER_STATUS
    },
    [PDETAIL_UPDATE]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение количества детали',
        form: {
            fields: [{
                component: TextField,
                props: {
                    id: 'Quantity',
                    name: 'Quantity',
                    label: 'Новое количество'
                }
            }],
            submitText: 'Обновить',
            validation: {
                Quantity: yup
                    .string('Лимит из списка')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Quantity: '',
            }
        },
        endpoint: endpoints.PDETAIL_UPDATE
    },
    [PDETAIL_CREATE]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Добавление детали к товару',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'DetailId',
                    name: 'DetailId',
                    label: 'Деталь',
                    endpoint: endpoints.DETAILS,
                    selectname: 'Name',
                    entity: 'details'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Quantity',
                    name: 'Quantity',
                    label: 'Количество'
                }
            }],
            submitText: 'Добавить',
            validation: {
                DetailId: yup
                    .string('Лимит из списка')
                    .required('Это обязательное поле'),
                Quantity: yup
                    .string('Лимит из списка')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                DetailId: '',
                Quantity: ''
            }
        },
        endpoint: endpoints.PDETAIL_CREATE
    },
    [PRODUCT_UPDATE_ETITYE]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение товара',
        form: {
            fields: [{
                component: SelectComponent,
                props: {
                    id: 'Etity',
                    name: 'Etity',
                    label: 'Поле',
                    endpoint: endpoints.PRODUCT_UPDATE_ETITYES,
                    selectname: 'Name',
                    entity: 'productvalues'
                }
            }, {
                component: TextField,
                props: {
                    id: 'Value',
                    name: 'Value',
                    label: 'Значение'
                }
            }],
            submitText: 'Изменить',
            validation: {
                Etity: yup
                    .string('Значение из списка')
                    .required('Это обязательное поле'),
                Value: yup
                    .string('Значение из списка')
                    .required('Это обязательное поле'),
            },
            initialValues: {
                Etity: '',
                Value: '',
            }
        },
        endpoint: endpoints.PRODUCT_UPDATE_ETITYE
    },
    [PRODUCT_UPDATE_PHOTO]: {
        hook: apiSlice.useProductPhotoUpdateMutation,
        title: 'Обновление изображения',
        form: {
            fields: [{
                component: InputPhoto,
                props: {
                    id: 'File',
                    name: 'File',
                    type: 'file',
                    accept: "image/*",
                    label: '',
                }
            }],
            submitText: 'Обновить',
            validation: {
            },
            initialValues: {
                File: '',
            }
        },
        endpoint: endpoints.PRODUCT_UPDATE_PHOTO
    },
    [ORDER_COMMENT]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Добавление комментария',
        form: {
            fields: [{
                component: TextField,
                props: {
                    id: 'Comment',
                    name: 'Comment',
                    label: 'Комментарий'
                }
            }],
            submitText: 'Добавить',
            validation: {
                Comment: yup
                    .string('Лимит из списка'),
            },
            initialValues: {
                Comment: '',
            }
        },
        endpoint: endpoints.ORDER_COMMENT
    },
    [PRODUCT_COPY]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Подтвердите копирование товара',
        form: {
            fields: [{
                component: Typography,
                props: {
                    children: 'Вы действительно хотите копировать этот товар ?',
                    sx: { mb: 3, color: '#000!important' }
                }
            }],
            submitText: 'Подтвердить',
            closeButton: 'Отменить',
            validation: {},
            initialValues: {}
        },
        endpoint: endpoints.DELETE
    },
    [APPLICATION_PRODUCT_QUANTITY]: {
        hook: apiSlice.useUpdateEntityMutation,
        title: 'Изменение кол-ва',
        form: {
            fields: [
                {
                    component: TextField,
                    props: {
                        id: 'Quantity',
                        name: 'Quantity',
                        label: 'Количество'
                    }
                }],
            submitText: 'Изменить',
            validation: {
                Quantity: yup
                    .number()
                    .typeError('Укажите значение числом')
                    .required(),
            },
            initialValues: {
                Quantity: ''
            }
        },
        endpoint: endpoints.DELETE
    },
    [APPLICATION_PRODUCT]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Добавление товара',
        form: {
            fields: [
                {
                    component: SelectComponent,
                    props: {
                        id: 'ProductId',
                        name: 'ProductId',
                        label: 'Товар',
                        endpoint: endpoints.PRODUCTS,
                        selectname: 'Name',
                        entity: 'products'
                    }
                },
                {
                    component: TextField,
                    props: {
                        id: 'Quantity',
                        name: 'Quantity',
                        label: 'Количество'
                    }
                }],
            submitText: 'Добавить',
            validation: {
                ProductId: yup
                    .string()
                    .required(),
                Quantity: yup
                    .number()
                    .typeError('Укажите значение числом')
                    .required(),
            },
            initialValues: {
                ProductId: '',
                Quantity: 1
            }
        },
        endpoint: endpoints.DELETE
    },
    [ORDER_CREATE]: {
        hook: apiSlice.useCreateEntityMutation,
        title: 'Подтвердите создание заказа',
        form: {
            fields: [{
                component: Typography,
                props: {
                    children: 'Вы подтверждаете действие ?',
                    sx: { mb: 3, color: '#000!important' }
                }
            }],
            submitText: 'Подтвердить',
            closeButton: 'Отменить',
            validation: {},
            initialValues: {}
        },
        endpoint: endpoints.DELETE
    },
}

export default modals