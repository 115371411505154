import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, createTheme, ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { Box } from '@mui/system'
import Router from './router'
import Alert from './components/Alert'
import Navigation from './components/Navigation'
import ModalComponent from './components/Modal'
import '../static/style/index.sass'
import { authCheckAction } from '../redux/actions/auth'

const App = () => {
    const dispatch = useDispatch()
    const { isAuth, isLoading } = useSelector((state) => state.user)
    const { isOpen: modalState } = useSelector((state) => state.modal)
    const { isOpen: alertState } = useSelector((state) => state.alert)

    useEffect(() => {
        dispatch(authCheckAction())
    }, [])

    const theme = createTheme({
        palette: {
            primary: {
                light: '#4dabf5',
                main: '#2196f3',
                dark: '#1769aa',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ff7961',
                main: '#f44336',
                dark: '#ba000d',
                contrastText: '#000',
            },
        },
    })

    const loaderBg = {
        minWidth: '100%',
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.light,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const loaderProgress = {
        color: 'white',
    }

    if (isLoading)
        return (
            <Box sx={loaderBg}>
                <CircularProgress size={80} sx={loaderProgress} />
            </Box>
        )

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Box sx={{ m: 'auto', mt: 3, mb: 14, pl: 1, pr: 1, maxWidth: '1080px', minHeight: '100vh' }}>
                    <Router isAuth={isAuth} />
                </Box>
                {alertState && <Alert />}
                {modalState && <ModalComponent />}
                {isAuth && <Navigation />}
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
