import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Grid, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { Fragment, useEffect } from 'react'
import { apiSlice } from '../../redux/slices/api'

const SuccessApplication = () => {
    const navigation = useNavigate()
    const params = new URL(document.location).searchParams
    const { data, isLoading, isError } = apiSlice.useClientApplicationQuery(params.get('order'))
    useEffect(() => {
        if (isError) return navigation('/application')
    }, [data, isLoading])
    if (isLoading && !data) return <Fragment />
    return (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ pt: '3rem', maxWidth: '1080px', position: 'relative' }}>
            <Link to="/application">
                <ArrowBackIcon sx={{ position: 'absolute', left: 18, top: 10, color: '#000' }} />
            </Link>
            <Typography sx={{ fontSize: '2rem', mb: '.7rem' }}>Спасибо!</Typography>
            <CheckCircleIcon sx={{ color: '#8bc34a', fontSize: '3.3rem', mb: '.9rem' }} />
            <Typography sx={{ textAlign: 'center', fontSize: '1.3rem', fontWeight: 'light' }}>
                У тебя все получилось!
                <br />
                Скоро с тобой свяжется наш специалист
            </Typography>
        </Grid>
    )
}

export default SuccessApplication
