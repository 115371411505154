import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import AccordionComponent from './Accordion'
import ClearIcon from '@mui/icons-material/Clear'
import { useDispatch } from 'react-redux'
import { modalSlice } from '../../redux/slices/modal'

const ApplicationItem = (props) => {
    const dispatch = useDispatch()
    const { openModal } = modalSlice.actions
    const { id, City, FixPrice, Name, Phone, Address, Passport, Commentaire, createdAt, products, DeliveryType } = props
    const { onDelete, onOrder } = props

    const styles = {
        text: {
            fontSize: 15,
        },
        buttonContainer: {
            mt: 2,
            width: 1,
        },
        createButton: {
            fontSize: 12,
            m: 1,
            textTransform: 'none',
        },
        button: {
            fontSize: 12,
            ml: 1,
            textTransform: 'none',
        },
    }

    const dateFormat = (data) => data.toLocaleDateString('ru-mo', { year: 'numeric', month: 'long', day: 'numeric' }).replace(/[/]/gi, '.')

    const tableStyles = {
        row: {
            width: 12,
            p: 1,
        },
    }

    const deleteHandler = (id) => dispatch(openModal({ cfg: 'DELETE', initialData: { id, updatePath: 'ApplicationProducts' } }))
    const quantityHandler = (id) => dispatch(openModal({ cfg: 'APPLICATION_PRODUCT_QUANTITY', initialData: { id, updatePath: 'application/product/quantity' } }))
    const productAdd = (id) => dispatch(openModal({ cfg: 'APPLICATION_PRODUCT', initialData: { id, updatePath: 'application/product' } }))

    const deliveryOptions = {
        pec: 'ПЭК',
        self: 'самовывоз',
        other: 'в другую страну',
    }


    return (
        <Stack m={1} spacing={3}>
            <AccordionComponent title={<Typography onClick={() => navigator.clipboard.writeText(Name)}>{Name}</Typography>}>
                <Stack m={1} spacing={1}>
                    <Typography sx={styles.text}>Номер: {Phone}</Typography>
                    <Typography sx={styles.text}>Паспорт: {Passport}</Typography>
                    <Typography sx={styles.text}>Комментарий к заказу: {Commentaire}</Typography>
                    <Typography sx={styles.text}>Дата создания заявки: {dateFormat(new Date(createdAt))}</Typography>
                    <Typography sx={styles.text}>Тип доставки: {deliveryOptions[DeliveryType]}</Typography>
                    {DeliveryType === 'pec' && (
                        <>
                            <Typography sx={styles.text}>Город: {City}</Typography>
                            <Typography sx={styles.text}>
                                {props?.PecDelivery?.isDeleviryToAddress ? 'Адрес' : 'ПВЗ'}: {Address}
                            </Typography>
                        </>
                    )}
                    {DeliveryType === 'other' && (
                        <>
                            <Typography sx={styles.text}>Город: {City}</Typography>
                            <Typography sx={styles.text}>Полный адрес: {Address}</Typography>
                        </>
                    )}
                </Stack>
                {FixPrice&&<Typography sx={styles.text}>Промокод: {FixPrice}</Typography>}
                <Stack m={1} spacing={1}>
                    <Typography sx={styles.text}>Товары:</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: 1 }} aria-label="simple table" size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        Товар
                                    </TableCell>
                                    <TableCell align="left" sx={tableStyles.row}>
                                        Кол-во
                                    </TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map((row, i) => (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left" sx={tableStyles.row}>
                                            {row.Product.Name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableStyles.row} onClick={() => quantityHandler(row.id)}>
                                            {row.Quantity}
                                        </TableCell>
                                        <TableCell align="left" sx={tableStyles.row} onClick={() => deleteHandler(row.id)}>
                                            <ClearIcon sx={{ fontSize: 13 }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
                <Box sx={styles.buttonContainer}>
                    <Button variant="contained" sx={{ ...styles.createButton, backgroundColor: '#4caf50' }} onClick={onOrder}>
                        Создать заказ
                    </Button>
                    <Button variant="contained" sx={{ ...styles.createButton, backgroundColor: '#4caf50' }} onClick={() => productAdd(id)}>
                        Добавить товар
                    </Button>
                    <Button variant="contained" sx={{ ...styles.button, backgroundColor: '#f44336' }} onClick={onDelete}>
                        Удалить
                    </Button>
                </Box>
            </AccordionComponent>
        </Stack>
    )
}

export default ApplicationItem
