import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiForm from '../../app/http/apiForm'
import { authLogoutAction } from '../actions/auth'



const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NODE_ENV !== "production" ? 'http://localhost:8080/api/' : '/api/',
    prepareHeaders: (headers) => {
        headers.set('content-type', 'application/json')
        headers.set('Authorization', `Bearer ${localStorage.getItem('Token')}`)
        return headers
    },
    credentials: 'include',
})

const baseQueryWithReAuth = async (args, api, extra) => {
    let responce = await baseQuery(args, api, extra)
    if (responce.error && responce.error.status === 401) {
        const refreshResult = await baseQuery('/check', api, extra)
        if (refreshResult.data) {
            localStorage.setItem('Token', refreshResult.data.Token)
            responce = await baseQuery(args, api, extra)
        } else api.dispatch(authLogoutAction())
    }
    return responce
}




export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReAuth,
    tagTypes: ['Product'],
    endpoints: (builder) => ({
        login: builder.mutation({
            query: ({ body }) => ({ url: '/auth', method: 'POST', body }),
            invalidatesTags: ['Auth'],
        }),
        refreshLogin: builder.mutation({
            query: ({ body }) => ({ url: '/check', method: 'POST', body }),
            invalidatesTags: ['Auth'],
        }),
        logout: builder.mutation({
            query: ({ body }) => ({ url: '/logout', method: 'POST', body }),
            invalidatesTags: ['Auth'],
        }),
        getEntity: builder.query({
            query: (path) => `/${path}`,
            providesTags: ({ providesTags }) => providesTags,
        }),
        deleteEntity: builder.mutation({
            query: ({ updatePath, body }) => ({ url: `/delete/${updatePath}`, method: 'DELETE', body }),
            invalidatesTags: (data) => data?.invalidatesTags || [],
        }),
        createEntity: builder.mutation({
            query: ({ updatePath, body }) => ({ url: `/${updatePath}`, method: 'POST', body }),
            invalidatesTags: (data) => data?.invalidatesTags || [],
        }),
        updateEntity: builder.mutation({
            query: ({ updatePath, body }) => ({ url: `/${updatePath}`, method: 'PUT', body }),
            invalidatesTags: (data) => data?.invalidatesTags || [],
        }),
        productCreate: builder.mutation({
            async queryFn(body, _queryApi, _extraOptions) {
                try {
                    const response = await apiForm({ method: 'POST', url: '/product', data: body })
                    return { data: response.data }
                } catch (error) {
                    return { error: error.response.data }
                }
            },
            invalidatesTags: ['Product', 'Detail'],
        }),
        applicationCreate: builder.mutation({
            query: ({ body }) => ({ url: '/application', method: 'POST', body }),
            invalidatesTags: ['Application'],
        }),
        productPhotoUpdate: builder.mutation({
            async queryFn({ body }, _queryApi, _extraOptions) {
                try {
                    const response = await apiForm({ method: 'PUT', url: '/product/photo', data: body })
                    return { data: response.data }
                } catch (error) {
                    return { error: error.response.data }
                }
            },
            invalidatesTags: (data) => data?.invalidatesTags || [],
        }),
        citySearch: builder.mutation({
            query: (query) => ({ url: `/pec/searchcity?city=${query}`, method: 'PUT' }),
            providesTags: ['Cities'],
        }),
        divisionsSearch: builder.mutation({
            query: (query) => ({ url: `/pec/searchdivisions?city=${query}`, method: 'PUT' }),
            providesTags: ['Cities'],
        }),
        clientApplication: builder.query({
            query: (id) => `/client/order/${id}`,
            providesTags: [],
        })
    }),
})

