import { useState } from 'react'
import endpoints from '../../configs/endpoints'
import { Box } from '@mui/system'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import OrderItem from '../components/OrderItem'
import { CircularProgress, Stack, Typography } from '@mui/material'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import { useDispatch } from 'react-redux'

const Orders = () => {
    const dispatch = useDispatch()
    const [isVisible, setVisible] = useState(false)
    const { openModal } = modalSlice.actions

    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.ORDERS.uri)

    const deleteHandler = (id) => dispatch(openModal({ cfg: 'DELETE', initialData: { id, updatePath: 'Order' } }))
    const statusHandler = (id) => dispatch(openModal({ cfg: 'ORDER_STATUS', initialData: { id, updatePath: 'order/status' } }))
    const noteHandler = (id) => dispatch(openModal({ cfg: 'ORDER_COMMENT', initialData: { id, updatePath: 'order/comment' } }))

    return isLoading || !data?.orders ? (
        <Box>
            <CircularProgress size={80} />
        </Box>
    ) : (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', m: 2, mb: 3 }}>
                <Stack>
                    <Typography variant="subtitle1" component="div">
                        В работе: {data.orders.processed.length}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        Завершенных: {data.orders.closed.length}
                    </Typography>
                </Stack>
                <Box onClick={() => setVisible(!isVisible)} sx={{ cursor: 'pointer' }}>
                    {isVisible ? <RemoveRedEyeOutlinedIcon /> : <RemoveRedEyeIcon />}
                </Box>
            </Box>
            {data.orders.processed.map((item) => (
                <OrderItem key={item.id} {...item} onDelete={() => deleteHandler(item.id)} onStatus={() => statusHandler(item.id)} onUpdateNote={() => noteHandler(item.id)} />
            ))}
            {isVisible ? (
                <>
                    {data.orders.postponed.map((item) => (
                        <OrderItem key={item.id} {...item} onDelete={() => deleteHandler(item.id)} onStatus={() => statusHandler(item.id)} onUpdateNote={() => noteHandler(item.id)} />
                    ))}
                    {data.orders.closed.map((item) => (
                        <OrderItem key={item.id} {...item} onDelete={() => deleteHandler(item.id)} onStatus={() => statusHandler(item.id)} onUpdateNote={() => noteHandler(item.id)} />
                    ))}
                </>
            ) : null}
        </Box>
    )
}

export default Orders
