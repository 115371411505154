import { Button, Card, Modal, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { createElement, Fragment, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import modals from '../../configs/modals'
import { modalSlice } from '../../redux/slices/modal'
import { alertSlice } from '../../redux/slices/alert'

const ModalComponent = () => {
    const dispatch = useDispatch()
    const { isOpen, cfg, initialData } = useSelector((state) => state.modal)

    const { closeModal } = modalSlice.actions
    const { openAlert } = alertSlice.actions

    const [file, setFile] = useState()
    const { title, form, hook } = modals[cfg]

    const [
        updateFn,
        {
            isLoading: { isLoading },
        },
    ] = hook()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }

    const formik = useFormik({
        initialValues: form.initialValues,
        validationSchema: yup.object(form.validation),
        onSubmit: (values, { setFieldError }) => {
            const form_data = new FormData()
            if (file) for (let key in values) form_data.append(key, values[key])
            if (file) for (let key in initialData) form_data.append(key, initialData[key])
            if (file) form_data.append('File', file)
            updateFn({ body: file ? form_data : { ...values, ...initialData }, updatePath: initialData?.updatePath })
                .unwrap()
                .then((data) => {
                    dispatch(closeModal())
                    if (data.message) dispatch(openAlert(data.message))
                })
                .catch((err) => {
                    err.data.fields?.map(({ name, msg }) => setFieldError(name, msg))
                    if (err.data?.message) dispatch(openAlert(err.data?.message))
                })
        },
    })

    return (
        <Modal open={isOpen} onClose={() => dispatch(closeModal())} aria-labelledby={title} aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '3rem',
                    }}
                >
                    <Box sx={{ position: 'absolute', right: 25, top: 27, cursor: 'pointer' }} onClick={() => dispatch(closeModal())}>
                        <CloseIcon />
                    </Box>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2.5 }}>
                        {title}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 1,
                            minWidth: '320px',
                        }}
                    >
                        {form.fields.map((field, index) => (
                            <Fragment key={index}>
                                {createElement(field.component, {
                                    ...field.props,
                                    value: formik.values[field.props.name],
                                    onChange: formik.handleChange,
                                    error: formik.touched[field.props.name] && Boolean(formik.errors[field.props.name]),
                                    helperText: formik.touched[field.props.name] && formik.errors[field.props.name],
                                    change: (field, data) => formik.setFieldValue(field, data),
                                    sx: { mb: 3, color: 'primary.main', ...field.props.sx },
                                    handlerFile: (e) => setFile(e.currentTarget.files[0]),
                                    file: file,
                                })}
                            </Fragment>
                        ))}
                        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ backgroundColor: form.submitColor ? form.submitColor : null }} disabled={isLoading}>
                            {form.submitText}
                        </Button>
                        {form.closeButton ? (
                            <Button color="primary" variant="contained" fullWidth onClick={() => dispatch(closeModal())} sx={{ mt: 2 }}>
                                {form.closeButton}
                            </Button>
                        ) : null}
                    </Box>
                </Card>
            </Box>
        </Modal>
    )
}

export default ModalComponent
