import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, CircularProgress } from '@mui/material'
import { Fragment } from 'react'
import endpoints from '../../configs/endpoints'
import ClearIcon from '@mui/icons-material/Clear'
import CircleButton from '../components/CircleButton'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import { useDispatch } from 'react-redux'

const Detail = () => {
    const dispatch = useDispatch()
    const { openModal } = modalSlice.actions

    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.DETAILS.uri)

    const limitTest = (remains, limits) => {
        if (!Object.keys(limits).length) return +remains > 0 ? { backgroundColor: '#fff' } : { backgroundColor: '#f44336', color: '#fff' }
        else if (limits.Low >= +remains) return { backgroundColor: '#f44336', color: '#fff' }
        else if (limits.Mid >= +remains) return { backgroundColor: '#ff9800', color: '#000' }
        else return { backgroundColor: '#4caf50', color: '#000' }
    }

    const tableStyles = {
        row: {
            width: 12,
            p: 1,
        },
    }

    const groupArray = (data) => {
        const detailsWithProvider = Array.from(new Set(data.filter((el) => el.Provider).map((item) => item.Provider.Name)))
        return [
            ...detailsWithProvider.map((item) =>
                data
                    .filter((el) => el.Provider)
                    .filter((device) => device.Provider.Name === item)
                    .sort((a, b) => (a.Name > b.Name ? 1 : -1))
            ),
            [...data.filter((el) => !el.Provider)],
        ]
    }

    const deleteHandler = (id) => dispatch(openModal({ cfg: 'DELETE', initialData: { id, updatePath: 'Detail' } }))
    const updateRemainsHandler = (id) => dispatch(openModal({ cfg: 'DETAIL_UPDATE', initialData: { id, updatePath: 'detail/remains' } }))
    const updateHandler = (id) => dispatch(openModal({ cfg: 'DETAIL_UPDATE_ETITYE', initialData: { id, updatePath: 'detail' } }))

    return (
        <Box>
            <CircleButton action={() => dispatch(openModal({ cfg: 'DETAIL_CREATE', initialData: { updatePath: 'detail' } }))} />
            {isLoading || !data?.details ? (
                <Box>
                    <CircularProgress size={80} />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: 1 }} aria-label="simple table" size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={tableStyles.row}>
                                    Пост.
                                </TableCell>
                                <TableCell align="left" sx={tableStyles.row}>
                                    Название
                                </TableCell>
                                <TableCell align="left" sx={tableStyles.row}>
                                    Цена
                                </TableCell>
                                <TableCell align="left" sx={tableStyles.row}>
                                    Остаток
                                </TableCell>
                                <TableCell align="left"> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupArray(data.details)
                                .slice(0, -1)
                                .map((group, i) => (
                                    <Fragment key={i}>
                                        {group.map((row, i) => (
                                            <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" sx={tableStyles.row} onClick={() => updateHandler(row.id)}>
                                                    {row?.Provider?.Name || '-'}
                                                </TableCell>
                                                <TableCell align="left" sx={tableStyles.row}>
                                                    {row.Name}
                                                </TableCell>
                                                <TableCell align="left" sx={tableStyles.row}>
                                                    {row.Price}₽
                                                </TableCell>
                                                <TableCell align="left" onClick={() => updateRemainsHandler(row.id)} sx={{ ...limitTest(row.Remains, row.limits), ...tableStyles.row }}>
                                                    {+row.Remains.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="left" sx={tableStyles.row} onClick={() => deleteHandler(row.id)}>
                                                    <ClearIcon sx={{ fontSize: 13 }} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableCell colSpan={5} sx={{ height: '1px', p: '0.5px', backgroundColor: '#000' }}></TableCell>
                                    </Fragment>
                                ))}
                            {groupArray(data.details)
                                .pop()
                                .map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell align="left" sx={tableStyles.row} onClick={() => updateHandler(row.id)}>
                                            {row?.Provider?.Name || '-'}
                                        </TableCell>
                                        <TableCell align="left" sx={tableStyles.row}>
                                            {row.Name}
                                        </TableCell>
                                        <TableCell align="left" sx={tableStyles.row}>
                                            {row.Price}₽
                                        </TableCell>
                                        <TableCell align="left" onClick={() => updateRemainsHandler(row.id)} sx={{ ...limitTest(row.Remains, row.limits), ...tableStyles.row }}>
                                            {row.Remains}
                                        </TableCell>
                                        <TableCell align="left" sx={tableStyles.row} onClick={() => deleteHandler(row.id)}>
                                            <ClearIcon sx={{ fontSize: 13 }} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    )
}

export default Detail
