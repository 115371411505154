import { Box, CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import endpoints from '../../configs/endpoints'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import CircleButton from '../components/CircleButton'
import ProductItem from '../components/ProductItem'

const Product = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { openModal } = modalSlice.actions

    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.PRODUCTS.uri)
    const deleteHandler = (id) => dispatch(openModal({ cfg: 'DELETE', initialData: { id, updatePath: 'Product' } }))
    const limitHandler = (id) => dispatch(openModal({ cfg: 'PRODUCT_LIMITS', initialData: { id, updatePath: 'product/limits' } }))
    const copyHandler = (id) => dispatch(openModal({ cfg: 'PRODUCT_COPY', initialData: { id, updatePath: 'product/copy' } }))

    return (
        <Box>
            <CircleButton action={() => navigate('/product/create')} />
            {isLoading || !data?.products ? (
                <Box>
                    <CircularProgress size={80} />
                </Box>
            ) : (
                data.products.map((item) => <ProductItem key={item.id} {...item} onDelete={() => deleteHandler(item.id)} onLimits={() => limitHandler(item.id)} onProductCopy={() => copyHandler(item.id)} />)
            )}
        </Box>
    )
}

export default Product
