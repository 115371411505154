import endpoints from '../../configs/endpoints'
import { Box, CircularProgress } from '@mui/material'
import ApplicationItem from '../components/ApplicationItem'
import { apiSlice } from '../../redux/slices/api'
import { modalSlice } from '../../redux/slices/modal'
import { useDispatch } from 'react-redux'
import CircleButton from '../components/CircleButton'
import { useNavigate } from 'react-router-dom'

const Application = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { openModal } = modalSlice.actions
    const { data, isLoading } = apiSlice.useGetEntityQuery(endpoints.APPLICATIONS.uri)

    return isLoading || !data?.applications ? (
        <Box>
            <CircularProgress size={80} />
        </Box>
    ) : (
        <Box>
            <CircleButton action={() => navigate('/application')} />
            {[...data.applications.filter(({ InWork }) => !InWork)].map((item) => (
                <ApplicationItem key={item.id} {...item} onDelete={() => dispatch(openModal({ cfg: 'DELETE', initialData: { id: item.id, updatePath: 'Application' } }))} onOrder={() => dispatch(openModal({ cfg: 'ORDER_CREATE', initialData: { ApplicationId: item.id, updatePath: 'order' } }))} />
            ))}
        </Box>
    )
}

export default Application
